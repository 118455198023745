.gallery-container {
  height:80vh;
  width:fit-content;
  margin: 0rem;
  overflow:hidden;
  cursor: pointer;
  position: relative;
  padding-top: 10vh;
}

.image-track {
  display: flex;
  gap: 4vmin;
  position: relative;
  left: 20vw;
  top: 35%;
  transform: translate(0%, -50%); 
  user-select: none; /* -- Prevent image highlighting -- */
}

.image-track > .gallery-image {
  width: 45vmin;
  height: 56vmin;
  object-fit: cover;
  object-position: 100% center;
}
