.sg-title-container {
  background-image: url('../../assets/small_group/sg-bkg.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.sg-title {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.sg-icon {
  opacity: 0.5;
  transition: 0.2s ease;
  margin-top: 7px;
  margin-right: 2px;

}

.sg-icon:hover {
  opacity: 1;
}

.sg-title-image {
  position: relative;
  margin: 5vw 6vw 0vw 6vw;
  object-fit: contain;
}

.sg-title-container-mobile .sg-title-image {
  position: relative;
  margin: 15vw 6vw 0vw 6vw;
}

.sg-title-container .text-container {
  position: relative;
  width: 35vw;
  margin-top: -18vw;
  margin-bottom: 10vw;
  align-self: center;
}

.sg-title-container-mobile .text-container {
  position: relative;
  width: 80vw;
  margin-top: 5vw;
  margin-bottom: 20vw;
  align-self: center;
}

.sg-leaders-section {
  background-image: url('../../assets/small_group/sg-bkg2.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sg-leaders-section .text-container {
  margin: 10vw 10vw 3vw 10vw;
  width: 72vw;
}

.sg-leaders-section-mobile h1 {
  font-size: 2.5rem;
}

.leaders {
  display: flex;
  width: 95vw;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 5vw;
  flex-wrap: wrap;
}

.leader {
  border-radius: 8px;
  box-shadow: 0px 5px 8px darkgray;
  background-color: white;
  padding: 10px;
  margin: 1vw;
  text-align: start;
  transition: box-shadow 200ms ease-in-out;
}

.leader:hover {
  box-shadow: 0px 12px 15px gray;
  cursor: pointer;
}

.leader p {
  padding: 0;
  margin: 0;
  color: gray;
}

.leader hr {
  background-color: gray;
}

.leader h3 {
  font-weight: normal;
  font-size: large;
  margin: 10px 0px 5px 0px;
  object-fit: contain;
}

.profile {
  width: 19vw;
  height: auto;
}

.profile-mobile {
  width: 80vw;
  height: auto;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 0.6s ease, transform 1.2s ease;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.sg-photo {
  display: block;
  width: 60%;
  height: auto;
  border-radius: 15px;
  margin: auto;
  margin-bottom: 2rem;
}

.sg-photo-mobile {
  width: 90%;
}

.sg-answer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}