.chimp-forms {
  justify-content: block;
  position: relative;
  display: block;
  /* background-color: aqua; */
  flex-direction: column;
  justify-self: center;
  justify-items: center;
  align-content: center;
  width: 100%;
  margin: auto;
}

.chimp-forms form {
  width: 100%;
}

.chimp-forms input {
  width: 100%;
  height: 4vh;
}

.chimp-forms button {
  min-width: 100px;
  margin-top: 4vh;
  align-self: center;
}

.chimp-forms .email-message {
  margin-top: 4vh;
  text-align:start;
}