.church-title-container {
  background-image: url('../../assets/church/church-bkg.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: auto;
  position: relative;
  display: flex;
}

.church-title-container .text-container {
  width: 40vw;
  color: white;
  margin-top: 40vh;
  margin-bottom: 40vh;
  margin-left: 12vw;
  text-shadow: 0px 2.5px black;
}

.church-title-container .text-container-mobile {
  display: block;
  /* flex-direction: column; */
  width: 70vw;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 32vh;
  margin-bottom: 32vh;
  margin-left: 15vw;
  color: white;
  text-shadow: 0px 2.5px black;
}

.church-section {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  width: 100vw;
  height: auto;
  align-items: center;
}

.church-section-mobile {
  flex-direction: column;
  padding-top: 10vh;
  padding-bottom: 10vh;
  text-align: center;
  justify-content: space-evenly;
  /* width: auto; */
}

.church-section-mobile .text-container-mobile {
  /* margin: 5vw; */
  margin-left: 10vw;
  margin-right: 10vw;
  justify-content: center;
  /* margin-bottom: 10vw; */
}

.church-section-left {
  background-color: #f5f5f5;
}

.church-section-left .text-container {
  margin: 5vw;
  margin-left: 10vw;
}

.church-section h2 {
  margin-bottom: 2vh;
}

.church-section-right .text-container {
  width: 40vw;
  margin: 5vw;
}

.church-image {
  width: 40vw;
  margin: 7vw 5vw 7vw 5vw;
}

.church-image-mobile {
  width: 75vw;
  margin: 5vw 5vw 0vw 5vw;
}

.button-container {
  position: relative;
  display: flex;
  text-align: center;
  margin-top: 4%;
  margin-bottom: 5%;
}

.button-container-mobile {
  justify-content: center;
}

button {
  border: solid black 1px;
  width: 7.5vw;
  min-width: 100px;
  height: 3.6vw;
  min-height: 50px;
  background-color: white;
  color: black;
  margin-right: 1.5vw;
  text-align: center;
  justify-content: center;
  border-radius: 25px;
  transition: 0.2s ease;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
}

button:hover {
  background-color: black;
  color: white;
}

.church-section-mobile .text-container-mobile h1 {
  font-size: xx-large;
}

.church-section-mobile .text-container-mobile h2 {
  font-size: x-large;
}