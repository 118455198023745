.contact-title-container {
  background-image: url('../../assets/contact/contact-bkg1.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.contact-title-container-mobile {
  flex-direction: column;
}

.contact-img {
  position: relative;
  width: 35vw;
  height: auto;
  margin: 18vh 5vw 18vh 0vw;
}

.contact-img-mobile {
  width: 60vw;
  height: auto;
  margin: 17vh 0vw 5vh 0vw;
}

.contact-title-container .text-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40vh 5vw 40vh 0vw;
  text-align: center;
  width: 30%;
}

.contact-title-container .text-container-mobile {
  margin: 0;
  margin-top: 5vh;
  margin-bottom: 20vh;
  width: 75%;
}

.icons {
  position: relative;
  align-items: center;
  padding: 20px;
  display: flex;
  justify-content: center;
  transition: 0.2s ease;
}

.icon {
  padding-inline: 15px;
  transition: 0.2s ease;
}

.icons:hover .icon {
  opacity: 0.5;
}

.icons .icon:hover {
  opacity: 1;
}

.exec-icon {
  opacity: 0.5;
  transition: 0.2s ease;
  margin-top: 7px;
  margin-right: 2px;

}

.exec-icon:hover {
  opacity: 1;
}

.exec-container {
  background-image: url('../../assets/contact/contact-bkg2.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15vh;
  padding-bottom: 10vh;
  text-align: center;
}

.exec-container h1 {
  padding-left: 5vw;
  padding-right: 5vw;
}

.exec-container-mobile h1 {
  font-size: 2.5rem;
}

.exec-members {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 3vw;
  margin-bottom: 1vw;
  flex-wrap: wrap;
}

.exec {
  border-radius: 8px;
  box-shadow: 0px 5px 8px darkgray;
  background-color: white;
  padding: 12px;
  margin: 2vw;
  text-align: start;
  /* transition: all 200ms ease-in-out; */
}

.exec:hover {
  /* transform: translateY(-5px); */
  box-shadow: 0px 12px 15px gray;
  cursor: pointer;
}

.exec-title {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.exec h3 {
  padding: 0;
  margin: 8px 0px 5px 0px;
}

.exec p {
  padding: 0;
  margin: 0;
}

.email-container {
  /* background-image: url('../../assets/contact/contact-bkg1.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  background-color: #f6f6f6;
  width: 100vw;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15vh;
  padding-bottom: 15vh;
  text-align: center;
}

.email-container .text-container {
  margin-left: 5vw;
  margin-right: 5vw;
}

.mailchimp-form {
  justify-content: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  width: 35%;
  height: auto;
  padding: 5vw;
  margin-top: 4vh;
  box-shadow: 0px 0px 30px 5px lightgray;
}

.mailchimp-form-mobile {
  width: 65%;
  padding: 10vw;
}