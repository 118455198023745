.footer-container {
  position: relative;
  display: block;
  align-items: center;
  width: 100vw;
  height: auto;
}

.footer-container .icons {
  position: relative;
  align-items: center;
  padding: 20px;
  display: flex;
  justify-content: center;
  transition: 0.2s ease;
}

.footer-container .icons .icon {
  padding-inline: 15px;
  transition: 0.2s ease;
}

.footer-container .icons:hover .icon {
  opacity: 0.5;
}

.footer-container .icons .icon:hover {
  opacity: 1;
}
