.mob-navbar-container {
  width: 100vw;
  height: auto;
  position: absolute;
  /* padding: 5% 5% 5% 5%; */
  /* padding-inline: 5% 5% 5% 5%;; */
  display: flex;
  justify-content: space-between; 
  align-items: center;
  z-index: 1;
}

.mob-logo {
  padding: 5% 5% 5% 5%;
}

/* Menu Icon */
.hamb{
  cursor: pointer;
  float: right;
  padding: 40px 20px;
  padding-right: 7vw;
  z-index: 2;
}/* Style label tag */

.hamb-line {
  background: lightgray;
  display: block;
  height: 2px;
  position: relative;
  width: 34px;

} /* Style span tag */

.hamb-line::before,
.hamb-line::after{
  background: lightgray;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}
.hamb-line::before{
  top: 5px;
}
.hamb-line::after{
  top: -5px;
}

.side-menu {
  display: none;
} /* Hide checkbox */

/* Toggle menu icon */
.side-menu:checked ~ nav{
  /* max-height: 100%; */
  max-width: 100%;
}
.side-menu:checked ~ .hamb .hamb-line {
  background: transparent;
}
.side-menu:checked ~ .hamb .hamb-line::before {
  transform: rotate(-45deg);
  top:0;
}
.side-menu:checked ~ .hamb .hamb-line::after {
  transform: rotate(45deg);
  top:0;
}

/* Nav menu */
.nav{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  overflow:scroll;
  text-align: center;
  align-items: center;
  padding-top: 15%;
  justify-content:center;
  top: 0;
}

.menu {
  /* padding-top: 400px; */
  text-align: start;
  display: block;
  margin-bottom: 100px;
}
.menu a {
  display: block;
  padding: 20px;
}

.menu a:hover {
  font-weight: bold;
}

.nav{
  position:fixed;
  /* max-height: 0; */
  max-width: 0;
  transition: max-width .5s ease-out;
}

.nav a {
  text-decoration: inherit !important;
  color: inherit !important;

}

.nav ul{
  list-style: none !important;
}

/* Sub nav */
.subnav {
  padding: 20px;
  /* align-items: center; */
}
.subnavbtn {
  padding: 0;
  margin: 0;
}
.subnav-content {
  width: 100%;
  /* z-index: 1; */
  padding-top: 20px;
  display: none;
}
.subnav-content a {
  text-decoration: none;
  padding: 10px;
  margin-left: 50px;
}
.subnav:hover .subnav-content {
  display: flex;
  flex-direction: column;
}

.subnav-content a:hover {
  font-weight: bold;
}
