body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 275;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body h1 {
  font-family: -apple-system, BlinkMacSystemFont, 'Nunito Sans', 'Helvetica', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 7.5vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body h2 {
  font-family: -apple-system, BlinkMacSystemFont, 'Nunito Sans', 'Helvetica', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 4.5vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
  overflow-x: hidden
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.Navbar {
  width: 100vw;
  height: 15vh;
  /* padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  position: absolute;
}

.logo {
  padding-left: 2vw;
  padding-top: 3vw;
  cursor: pointer;
}
.tabs {
  padding-right: 4vw;
  padding-top: 3vw;
  display: flex;
  list-style: none;
  /* margin-right: 5vw; */
}

.tab {
  padding: 0 1.5rem;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight:normal;
}

.tab:hover {
  color:black !important;
}

.tabs-light .tab:hover {
  color: lightgray !important;
}

Link {
  color: inherit;
  text-decoration: inherit;
  padding: inherit;
  cursor: inherit;
  display: inherit;
}

.tabs-light .tab Link:hover {
  color:lightgray !important;
}

.Dropdown {
  position: absolute;
  display:flex;
  align-self: center;
  width: 140px;
  height: 100px;
}

.items {
  display: flex;
  list-style: none;
  flex-direction: column;
  align-self:flex-start;
  align-items: center;
  padding-left: 1.7rem;
  margin-top: 10vh;
  color: black;
}
.items-light {
  display: flex;
  list-style: none;
  flex-direction: column;
  padding-left: 1.7rem;
  margin-top: 10vh;
  color: white !important;
}

.item {
  padding: 0 0 0.75rem;
  cursor: pointer;
  transition: 0.3s;
}

a {
  position: relative;
}

.items-light .item a::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  border-radius: 4px;
  background-color: white;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

.item a::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  border-radius: 4px;
  background-color: black;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

.item a:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

/* .item:nth-child(2) {
  transition-delay: 50ms;
}
.item:nth-child(3) {
  transition-delay: 100ms;
} */

.hidden {
  opacity: 0;
  display: none;
  transform: translate(0%, -50%);
  transition: opacity 0.3s transform 0.2s;
}
.show {
  opacity: 1;
  display: flex;
  transform: translate(0%, -2vh);
  transition: opacity 0.3s transform 0.2s;
}




.mob-navbar-container {
  width: 100vw;
  height: auto;
  position: absolute;
  /* padding: 5% 5% 5% 5%; */
  /* padding-inline: 5% 5% 5% 5%;; */
  display: flex;
  justify-content: space-between; 
  align-items: center;
  z-index: 1;
}

.mob-logo {
  padding: 5% 5% 5% 5%;
}

/* Menu Icon */
.hamb{
  cursor: pointer;
  float: right;
  padding: 40px 20px;
  padding-right: 7vw;
  z-index: 2;
}/* Style label tag */

.hamb-line {
  background: lightgray;
  display: block;
  height: 2px;
  position: relative;
  width: 34px;

} /* Style span tag */

.hamb-line::before,
.hamb-line::after{
  background: lightgray;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}
.hamb-line::before{
  top: 5px;
}
.hamb-line::after{
  top: -5px;
}

.side-menu {
  display: none;
} /* Hide checkbox */

/* Toggle menu icon */
.side-menu:checked ~ nav{
  /* max-height: 100%; */
  max-width: 100%;
}
.side-menu:checked ~ .hamb .hamb-line {
  background: transparent;
}
.side-menu:checked ~ .hamb .hamb-line::before {
  transform: rotate(-45deg);
  top:0;
}
.side-menu:checked ~ .hamb .hamb-line::after {
  transform: rotate(45deg);
  top:0;
}

/* Nav menu */
.nav{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  overflow:scroll;
  text-align: center;
  align-items: center;
  padding-top: 15%;
  justify-content:center;
  top: 0;
}

.menu {
  /* padding-top: 400px; */
  text-align: start;
  display: block;
  margin-bottom: 100px;
}
.menu a {
  display: block;
  padding: 20px;
}

.menu a:hover {
  font-weight: bold;
}

.nav{
  position:fixed;
  /* max-height: 0; */
  max-width: 0;
  transition: max-width .5s ease-out;
}

.nav a {
  text-decoration: inherit !important;
  color: inherit !important;

}

.nav ul{
  list-style: none !important;
}

/* Sub nav */
.subnav {
  padding: 20px;
  /* align-items: center; */
}
.subnavbtn {
  padding: 0;
  margin: 0;
}
.subnav-content {
  width: 100%;
  /* z-index: 1; */
  padding-top: 20px;
  display: none;
}
.subnav-content a {
  text-decoration: none;
  padding: 10px;
  margin-left: 50px;
}
.subnav:hover .subnav-content {
  display: flex;
  flex-direction: column;
}

.subnav-content a:hover {
  font-weight: bold;
}

.main-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

h1 {
  padding: 0;
  margin: 0;
}

.home-title-container {
  background-image: url(/static/media/home-bkg2.98e7f4f1.svg);
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.home-title-container .text-container {
  margin-top: 60vh;
  margin-bottom: 20vh;
  padding-left: 8vw;
}

.home-image {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 18vh;
  margin-right: 6vw;
  margin-left: 2vw;
  margin-bottom: 15vh;
  overflow: hidden;
  object-fit:contain;
}

.home-title-container-mobile {
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}

.home-title-container-mobile .home-image {
  margin:0;
  padding: 0;
  margin-top: 20vh;
  width: 80vw;
  height: auto;
}

.home-title-container-mobile .text-container {
  margin: 0;
  padding: 0;
  margin-top: 8vh;
  margin-bottom: 20vh;
  padding-left: 8vw;
  padding-right: 5vw;
}

.vision-container {
  background-image: url(/static/media/vision-bkg.4ec2737c.svg);
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.vision-container-mobile {
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.vision-container .text-container {
  margin-top: 8%;
  padding-left: 8vw;
  margin-bottom: 8%;
}

.vision-container p {
  width: 35vw;
}

.vision-container-mobile .text-container {
  margin-top: 10vh;
  padding-left: 5vw;
  padding-right: 5vw;
  margin-bottom: 0vh;
}
.vision-container-mobile p {
  width: 80vw;
}

.vision-image {
  width: 40vw !important;
  height: auto !important;
  padding:8% !important;
  margin-top: 10% !important;
}

.vision-container-mobile .vision-image {
  width: 60vw !important;
  height: auto;
  margin-bottom: 5vh;
}

.campus-container {
  background-image: url(/static/media/fellowship-bkg.c8412db0.svg);
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  width: 100%;
  height: auto;
  position: relative;
  align-items:end;
}

.campus-container .text-container {
  margin-top: 8%;
  padding-right: 8vw;
  margin-bottom: 25%;
  text-align: end;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.campus-container-mobile .text-container {
  margin-top: 10vh;
  padding-right: 5vw;
  padding-left: 5vw;
  margin-bottom: 15vh;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.campus-container p {
  width: 35vw;
}

.campus-container-mobile p {
  width: 80vw;
  color: black;
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  margin-top: 50px;
}

.footer-container {
  position: relative;
  display: block;
  align-items: center;
  width: 100vw;
  height: auto;
}

.footer-container .icons {
  position: relative;
  align-items: center;
  padding: 20px;
  display: flex;
  justify-content: center;
  transition: 0.2s ease;
}

.footer-container .icons .icon {
  padding-inline: 15px;
  transition: 0.2s ease;
}

.footer-container .icons:hover .icon {
  opacity: 0.5;
}

.footer-container .icons .icon:hover {
  opacity: 1;
}

.about-title-container {
  background-image: url(/static/media/about2025.11847631.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  overflow: visible;
  position: relative;
  display: flex;
  justify-content: center;
}

.about-title-container h1 {
  /* padding: 23%; */
  padding-top: 50vh;
  padding-bottom: 50vh;
  color: white;
  /* text-align: center; */
}

.about-section {
  display: flex;
  position: relative;
  /* text-align: center; */
  /* justify-content: space-between; */
  /* margin: 5vw; */
  width: 100vw;
  height: auto;
}

.about-section-mobile {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  align-items: center;
  width: 100vw;
  height: auto;
  justify-content: center;
}

.about-text {
  width: 38vw;
  padding: 10vw;
  /* padding-left: 10vw; */
}

.about-text-mobile {
  text-align: center;
  justify-content: center;
  width: 80vw;
  padding: 10vw 0vw 5vw 0vw;
}

.about-image {
  object-fit: contain;
  padding-top: 10vw;
  padding-bottom: 10vw;
  padding-right: 10vw;
  /* padding: 10vw; */
  width: 30vw;
  height: auto;
}

.about-image-mobile {
  padding: 5vw;
  width: 90vw;
  height: auto;
}
.lg-title-container {
  background-image: url(/static/media/sg-bkg.98e7f4f1.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
}

.lg-title-container .text-container {
  text-align: center;
  color: black;
  margin: 55vh 20vw 25vh 20vw;
}

.lg-content-container {
  width: 100vw;
  height: auto;
  background: linear-gradient(0deg, black 0%, lightgray 100%);
  position: relative;
  text-align: center;
  padding: 10vh 0vw 0vh 0vw;
}

.flyer-container {
  position: relative;
  width: 100vw;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 8vh 0vw 0vh 0vw;
  overflow: hidden;
}

.flyer {
  display: block;
  width: 45%;
  height: auto;
  margin: auto;
  border-radius: 20px;
  border: white solid 10px;
  overflow: hidden;
}

.flyer-mobile {
  width: 75%;
}

.algae-container {
  width: 100%;
  display: block;
  justify-self: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 20vh 0vw 0vh 0vw;
  overflow: hidden;
}

.algae-container .text-container {
  margin-left: 20vw;
  margin-right: 20vw;
}
.gallery-container {
  height:80vh;
  width:-moz-fit-content;
  width:fit-content;
  margin: 0rem;
  overflow:hidden;
  cursor: pointer;
  position: relative;
  padding-top: 10vh;
}

.image-track {
  display: flex;
  grid-gap: 4vmin;
  gap: 4vmin;
  position: relative;
  left: 20vw;
  top: 35%;
  transform: translate(0%, -50%); 
  -webkit-user-select: none; 
          user-select: none; /* -- Prevent image highlighting -- */
}

.image-track > .gallery-image {
  width: 45vmin;
  height: 56vmin;
  object-fit: cover;
  object-position: 100% center;
}

.profile-container {
  display: block;
  padding: 20px 20px 30px 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  justify-self: center;
  margin: 0px 5% 0px 5%;
  border-bottom: 2px solid #f6f6f6;
}

.profile-img {
  transition: all 0.1s ease;
  width: 14vw;
  height: auto; 
}

.profile-img-mobile {
  transition: all 0.1s ease;
  width: 30vw;
  height: auto; 
}

.profile-img:hover {
  transform: scale(1.05);
}

.profile-container h2 {
  margin-bottom: 2vh;
  padding-bottom: 0;
}
.profile-container p {
  margin: 0;
  padding: 0;
}

.question-container {
  margin: 2vh 3vw 2vh 3vw;
  display: flex;
  padding: 2vh 2vw 2vh 2vw;
}

.question-container .question-img {
  position: relative;
  min-width: 60px;
  width: 4vw;
  height: auto;
  align-self: flex-start;
  transform: translate(0%, 45%)
}

.question-container .text-container {
  width: 80%;
  height: auto;
  margin-left: -2vw;
  padding: 2vh 4vw 4vh 6vw;
  background-color: #f6f6f6;
  border-radius: 15px;
  left: -10%;
}

.question-container-mobile {
  margin: 2vh 2vw 2vh 2vw;
  display: flex;
  padding: 2vh 2vw 2vh 2vw;
}

.question-container-mobile .text-container {
  width: 90%;
  height: auto;
  padding: 1vh 5vw 2vh 5vw;
  background-color: #f6f6f6;
  border-radius: 15px;
}

.question-container-mobile h2 {
  font-size: 1.5rem;
}
.sg-title-container {
  background-image: url(/static/media/sg-bkg.98e7f4f1.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.sg-title {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.sg-icon {
  opacity: 0.5;
  transition: 0.2s ease;
  margin-top: 7px;
  margin-right: 2px;

}

.sg-icon:hover {
  opacity: 1;
}

.sg-title-image {
  position: relative;
  margin: 5vw 6vw 0vw 6vw;
  object-fit: contain;
}

.sg-title-container-mobile .sg-title-image {
  position: relative;
  margin: 15vw 6vw 0vw 6vw;
}

.sg-title-container .text-container {
  position: relative;
  width: 35vw;
  margin-top: -18vw;
  margin-bottom: 10vw;
  align-self: center;
}

.sg-title-container-mobile .text-container {
  position: relative;
  width: 80vw;
  margin-top: 5vw;
  margin-bottom: 20vw;
  align-self: center;
}

.sg-leaders-section {
  background-image: url(/static/media/sg-bkg2.ad47c20a.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sg-leaders-section .text-container {
  margin: 10vw 10vw 3vw 10vw;
  width: 72vw;
}

.sg-leaders-section-mobile h1 {
  font-size: 2.5rem;
}

.leaders {
  display: flex;
  width: 95vw;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 5vw;
  flex-wrap: wrap;
}

.leader {
  border-radius: 8px;
  box-shadow: 0px 5px 8px darkgray;
  background-color: white;
  padding: 10px;
  margin: 1vw;
  text-align: start;
  transition: box-shadow 200ms ease-in-out;
}

.leader:hover {
  box-shadow: 0px 12px 15px gray;
  cursor: pointer;
}

.leader p {
  padding: 0;
  margin: 0;
  color: gray;
}

.leader hr {
  background-color: gray;
}

.leader h3 {
  font-weight: normal;
  font-size: large;
  margin: 10px 0px 5px 0px;
  object-fit: contain;
}

.profile {
  width: 19vw;
  height: auto;
}

.profile-mobile {
  width: 80vw;
  height: auto;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 0.6s ease, transform 1.2s ease;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.sg-photo {
  display: block;
  width: 60%;
  height: auto;
  border-radius: 15px;
  margin: auto;
  margin-bottom: 2rem;
}

.sg-photo-mobile {
  width: 90%;
}

.sg-answer {
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
}
.contact-title-container {
  background-image: url(/static/media/contact-bkg1.8e8762ce.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.contact-title-container-mobile {
  flex-direction: column;
}

.contact-img {
  position: relative;
  width: 35vw;
  height: auto;
  margin: 18vh 5vw 18vh 0vw;
}

.contact-img-mobile {
  width: 60vw;
  height: auto;
  margin: 17vh 0vw 5vh 0vw;
}

.contact-title-container .text-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40vh 5vw 40vh 0vw;
  text-align: center;
  width: 30%;
}

.contact-title-container .text-container-mobile {
  margin: 0;
  margin-top: 5vh;
  margin-bottom: 20vh;
  width: 75%;
}

.icons {
  position: relative;
  align-items: center;
  padding: 20px;
  display: flex;
  justify-content: center;
  transition: 0.2s ease;
}

.icon {
  padding-inline: 15px;
  transition: 0.2s ease;
}

.icons:hover .icon {
  opacity: 0.5;
}

.icons .icon:hover {
  opacity: 1;
}

.exec-icon {
  opacity: 0.5;
  transition: 0.2s ease;
  margin-top: 7px;
  margin-right: 2px;

}

.exec-icon:hover {
  opacity: 1;
}

.exec-container {
  background-image: url(/static/media/contact-bkg2.b1d1bffc.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15vh;
  padding-bottom: 10vh;
  text-align: center;
}

.exec-container h1 {
  padding-left: 5vw;
  padding-right: 5vw;
}

.exec-container-mobile h1 {
  font-size: 2.5rem;
}

.exec-members {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 3vw;
  margin-bottom: 1vw;
  flex-wrap: wrap;
}

.exec {
  border-radius: 8px;
  box-shadow: 0px 5px 8px darkgray;
  background-color: white;
  padding: 12px;
  margin: 2vw;
  text-align: start;
  /* transition: all 200ms ease-in-out; */
}

.exec:hover {
  /* transform: translateY(-5px); */
  box-shadow: 0px 12px 15px gray;
  cursor: pointer;
}

.exec-title {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.exec h3 {
  padding: 0;
  margin: 8px 0px 5px 0px;
}

.exec p {
  padding: 0;
  margin: 0;
}

.email-container {
  /* background-image: url('../../assets/contact/contact-bkg1.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  background-color: #f6f6f6;
  width: 100vw;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15vh;
  padding-bottom: 15vh;
  text-align: center;
}

.email-container .text-container {
  margin-left: 5vw;
  margin-right: 5vw;
}

.mailchimp-form {
  justify-content: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  width: 35%;
  height: auto;
  padding: 5vw;
  margin-top: 4vh;
  box-shadow: 0px 0px 30px 5px lightgray;
}

.mailchimp-form-mobile {
  width: 65%;
  padding: 10vw;
}
.chimp-forms {
  justify-content: block;
  position: relative;
  display: block;
  /* background-color: aqua; */
  flex-direction: column;
  justify-self: center;
  justify-items: center;
  align-content: center;
  width: 100%;
  margin: auto;
}

.chimp-forms form {
  width: 100%;
}

.chimp-forms input {
  width: 100%;
  height: 4vh;
}

.chimp-forms button {
  min-width: 100px;
  margin-top: 4vh;
  align-self: center;
}

.chimp-forms .email-message {
  margin-top: 4vh;
  text-align:start;
}
.church-title-container {
  background-image: url(/static/media/church-bkg.eefc26b1.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: auto;
  position: relative;
  display: flex;
}

.church-title-container .text-container {
  width: 40vw;
  color: white;
  margin-top: 40vh;
  margin-bottom: 40vh;
  margin-left: 12vw;
  text-shadow: 0px 2.5px black;
}

.church-title-container .text-container-mobile {
  display: block;
  /* flex-direction: column; */
  width: 70vw;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 32vh;
  margin-bottom: 32vh;
  margin-left: 15vw;
  color: white;
  text-shadow: 0px 2.5px black;
}

.church-section {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  width: 100vw;
  height: auto;
  align-items: center;
}

.church-section-mobile {
  flex-direction: column;
  padding-top: 10vh;
  padding-bottom: 10vh;
  text-align: center;
  justify-content: space-evenly;
  /* width: auto; */
}

.church-section-mobile .text-container-mobile {
  /* margin: 5vw; */
  margin-left: 10vw;
  margin-right: 10vw;
  justify-content: center;
  /* margin-bottom: 10vw; */
}

.church-section-left {
  background-color: #f5f5f5;
}

.church-section-left .text-container {
  margin: 5vw;
  margin-left: 10vw;
}

.church-section h2 {
  margin-bottom: 2vh;
}

.church-section-right .text-container {
  width: 40vw;
  margin: 5vw;
}

.church-image {
  width: 40vw;
  margin: 7vw 5vw 7vw 5vw;
}

.church-image-mobile {
  width: 75vw;
  margin: 5vw 5vw 0vw 5vw;
}

.button-container {
  position: relative;
  display: flex;
  text-align: center;
  margin-top: 4%;
  margin-bottom: 5%;
}

.button-container-mobile {
  justify-content: center;
}

button {
  border: solid black 1px;
  width: 7.5vw;
  min-width: 100px;
  height: 3.6vw;
  min-height: 50px;
  background-color: white;
  color: black;
  margin-right: 1.5vw;
  text-align: center;
  justify-content: center;
  border-radius: 25px;
  transition: 0.2s ease;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
}

button:hover {
  background-color: black;
  color: white;
}

.church-section-mobile .text-container-mobile h1 {
  font-size: xx-large;
}

.church-section-mobile .text-container-mobile h2 {
  font-size: x-large;
}
.fellowship-container {
  background-image: url(/static/media/fellowship-bkg.4263f196.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-evenly;
}

.fellowship-container-mobile {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fellowship-left {
  position: relative;
  margin: 10vh 5vw 15vh 5vw;
  /* width: 25vw;
  height: auto; */
}

.fellowship-container-mobile .fellowship-left {
  margin: 15vh 5vw 0vh 5vw;
}

.fship1 {
  width: 38vw;
  height: auto;
}

.fellowship-container-mobile .fellowship-left .fship1 {
  width: 50vw;
  height: auto;
}

.fship2 {
  margin-top: 3vw;
  /* margin-left: -7vw; */
  width: 18vw;
  height: auto;
}

.fellowship-container-mobile .fellowship-left .fship2 {
  margin-top: 3vw;
  width: 20vw;
  height: auto;
}

.fellowship-right {
  margin: 25vh 5vw 15vh -5vw;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 30vw;
  text-align: center;
  color: white;
}

.fellowship-container-mobile .fellowship-right {
  margin: 5vh 5vw 10vh 5vw;
  width: 85vw;

}


.fellowship-section {
  background-image: url(/static/media/fellowship-bkg2.4511b38e.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: auto;
  position: relative;
  justify-content: center;
  text-align: center;
  color: white;
  align-items: center;
}

.fellowship-section .text-container {
  margin: 15vh 5vw 20vh 5vw;
}

.text-container-mobile h1 {
  font-size: 2.5rem;
}

.fellowship-section h1 {
  padding-left: 5vw;
  padding-right: 5vw;
}

.fellowship-section a {
  color: inherit;
  text-decoration: inherit;
  padding: inherit;
  cursor: inherit;
  display: inherit;
  cursor: pointer;
  transition: 0.05s;
}

.fellowship-section a:hover {
  font-weight: bold;
}
