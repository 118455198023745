.lg-title-container {
  background-image: url('../../assets/small_group/sg-bkg.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
}

.lg-title-container .text-container {
  text-align: center;
  color: black;
  margin: 55vh 20vw 25vh 20vw;
}

.lg-content-container {
  width: 100vw;
  height: auto;
  background: linear-gradient(0deg, black 0%, lightgray 100%);
  position: relative;
  text-align: center;
  padding: 10vh 0vw 0vh 0vw;
}

.flyer-container {
  position: relative;
  width: 100vw;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 8vh 0vw 0vh 0vw;
  overflow: hidden;
}

.flyer {
  display: block;
  width: 45%;
  height: auto;
  margin: auto;
  border-radius: 20px;
  border: white solid 10px;
  overflow: hidden;
}

.flyer-mobile {
  width: 75%;
}

.algae-container {
  width: 100%;
  display: block;
  justify-self: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 20vh 0vw 0vh 0vw;
  overflow: hidden;
}

.algae-container .text-container {
  margin-left: 20vw;
  margin-right: 20vw;
}