.Navbar {
  width: 100vw;
  height: 15vh;
  /* padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  position: absolute;
}

.logo {
  padding-left: 2vw;
  padding-top: 3vw;
  cursor: pointer;
}
.tabs {
  padding-right: 4vw;
  padding-top: 3vw;
  display: flex;
  list-style: none;
  /* margin-right: 5vw; */
}

.tab {
  padding: 0 1.5rem;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight:normal;
}

.tab:hover {
  color:black !important;
}

.tabs-light .tab:hover {
  color: lightgray !important;
}

Link {
  color: inherit;
  text-decoration: inherit;
  padding: inherit;
  cursor: inherit;
  display: inherit;
}

.tabs-light .tab Link:hover {
  color:lightgray !important;
}
